import React, { useEffect, useState, useRef } from 'react';
import { TrackLoop, useSwipe } from '@livekit/components-react';

export function CustomGridLayout({ tracks, ...props }: any) {
  const gridEl = useRef<HTMLDivElement>(null);

  const [layout, setLayout] = useState({ columns: 1, rows: 1, maxTiles: 1 });

  useEffect(() => {
    if (tracks.length <= 4) {
      // For up to 4 participants, use side-by-side layout
      const columns = Math.min(tracks.length, 4);
      setLayout({ columns, rows: 1, maxTiles: tracks.length });
    } else if (tracks.length === 5) {
      // For exactly 5 participants, set a custom 2-row layout (2 in the first row and 3 in the second)
      setLayout({ columns: 2, rows: 2, maxTiles: 5 });
    } else if (tracks.length === 6) {
      // For exactly 6 participants, set a 3x2 grid layout
      setLayout({ columns: 3, rows: 2, maxTiles: 6 });
    }
  }, [tracks.length]);

  useSwipe(gridEl, {
    onLeftSwipe: () => console.log('Swiped left'),
    onRightSwipe: () => console.log('Swiped right'),
  });

  return (
    <div ref={gridEl} className="custom-grid-layout" style={{ height: '100%', width: '100%' }}>
      {tracks.length === 5 ? (
        // Custom layout for 5 participants
        <>
          <div
            className="row row1"
            style={{
              display: 'flex',
              height: '60vh',
              width: '100%',
              justifyContent: 'space-evenly', // Ensure videos are evenly spaced
            }}
          >
            {tracks.slice(0, 2).map((track:any, index:any) => (
              <div key={index} style={{ flex: '1 0 50%', display: 'flex', padding: '5px' }}>
                <TrackLoop tracks={[track]}>{props.children}</TrackLoop>
              </div>
            ))}
          </div>
          <div
            className="row row2"
            style={{
              display: 'flex',
              height: '40vh',
              width: '100%',
              justifyContent: 'space-evenly', // Ensure videos are evenly spaced
            }}
          >
            {tracks.slice(2, 5).map((track:any, index:any) => (
              <div key={index} style={{ flex: '1 0 33%', display: 'flex', padding: '5px' }}>
                <TrackLoop tracks={[track]}>{props.children}</TrackLoop>
              </div>
            ))}
          </div>
        </>
      ) : tracks.length === 6 ? (
        // Custom layout for 6 participants (3x2 grid layout)
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', // Ensures 3 equal columns
            gridTemplateRows: 'repeat(2, minmax(0, 1fr))', // Ensures 2 equal rows
            height: '100%',
            width: '100%',
            gap: '10px', // Optional gap between videos
          }}
        >
          <TrackLoop tracks={tracks}>
            {props.children}
          </TrackLoop>
        </div>
      ) : (
        // Default grid layout for other cases
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: `repeat(${layout.columns}, 1fr)`,
            gridTemplateRows: layout.rows === 2 ? '60vh 40vh' : '1fr',
            height: '100%',
            width: '100%',
          }}
        >
          <TrackLoop tracks={tracks}>{props.children}</TrackLoop>
        </div>
      )}
    </div>
  );
}
