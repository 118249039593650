import {
  GridLayout,
  LiveKitRoom,
  ParticipantTile,
  RoomAudioRenderer,
  useRoomContext,
  useTracks,
} from '@livekit/components-react';
import EgressHelper from '@livekit/egress-sdk';
import { ConnectionState, DataPacket_Kind, Participant, RoomEvent, Track } from 'livekit-client';
import { ReactElement, useEffect, useState } from 'react';
import SingleSpeakerLayout from './SingleSpeakerLayout';
import SpeakerLayout from './SpeakerLayout';
import Timer from './Timer';
import { CustomGridLayout } from './SideBySideLayout';
import ManualFocusSpeaker from './ManualFocusSpeaker';
import axios from 'axios';

interface RoomPageProps {
  url: string;
  token: string;
  layout: string;
}

export default function RoomPage({ url, token, layout }: RoomPageProps) {
  const [error, setError] = useState<Error>();
  if (!url || !token) {
    return <div className="error">missing required params url and token</div>;
  }

  const urlParams = new URLSearchParams(window.location.search);
  const paramValue:any = urlParams.get('muted');

  return (
    <LiveKitRoom serverUrl={url} token={token} onError={setError}>
      {error ? <div className="error">{error.message}</div> : <CompositeTemplate layout={layout} muted={paramValue}/>}
    </LiveKitRoom>
  );
}


const marqueeContainerStyle: React.CSSProperties = {
  backgroundColor: '#8e4ec6',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  width: '100%',
  height: "3.125em", // 50px -> 3.125em
  color: "white",
  position: 'fixed', // Fix it to the viewport
  bottom: 0, // Position it at the bottom
  left: 0, // Ensure it covers the whole width
  zIndex: 1000, // Make sure it stays on top of other elements
  display: 'flex', // Use flexbox
  alignItems: 'center', // Center vertically
};

const marqueeStyle: React.CSSProperties = {
  display: 'inline-block',
  animation: 'marquee 10s linear infinite',
  paddingLeft: '100%', // Start off-screen
};

const textStyle: React.CSSProperties = {
  display: 'inline-block',
  padding: '0.9375em', // 15px -> 0.9375em
  fontSize: "1.25em", // 20px -> 1.25em
  fontWeight: "bold",
  whiteSpace: 'nowrap',
};

const logoContainerStyle: React.CSSProperties = {
  position: 'fixed', // Fix to the viewport
  top: 0, // Position it at the top
  right: '5em', // 80px -> 5em
  zIndex: 1000, // Make sure it stays on top of other elements
  padding: '0.625em', // 10px -> 0.625em
};

const logoStyle: React.CSSProperties = {
  width: '3.75em', // 60px -> 3.75em
  height: 'auto', // Maintain aspect ratio
};

const loadInitialData = async (roomName: string, initialLayout: any) => {
  try {
    const response = await axios.get(`https://streaming.wiseuptv.club/room-data/${roomName}`);
    console.log(response);
    
    const data = response.data;
    console.log(data);
    console.log("loadInitialData");
    
   
    const admin = response.data["admin"]
     
    
    // Set default values if not found in the response
    return {
      layout: data.layout,
      scrollText: data.scrollText,
      admin: data.admin,
      volume_admin: data[admin],
      volume_VirtualDJ: data["VirtualDJ"]
    };
  } catch (error) {
    console.error("Error loading room data:", error);
    return {
      layout: "",
      scrollText: ""
    };
  }
};

const saveDataToFile = async (roomName: string, key: string, value: any) => {
  try {
    await axios.post('https://streaming.wiseuptv.club/room-data', { roomName, key, value });
    console.log(`Saved ${key} for room ${roomName}`);
  } catch (error) {
    console.error(`Error saving ${key} for room ${roomName}:`, error);
  }
};

function CompositeTemplate({ layout: initialLayout, muted }: any) {
  const room = useRoomContext();
  const [layout, setLayout] = useState(initialLayout);
  const [playAll, setPlayAll] = useState(false);
  const [hasScreenShare, setHasScreenShare] = useState(false);
  const screenshareTracks = useTracks([Track.Source.ScreenShare], {
    onlySubscribed: true,
  });
  const [decoder] = useState(() => new TextDecoder());
  const [scrollText, setScrollText] = useState("")
  const [channleLogo, setChannleLogo] = useState("")

  useEffect(() => {
    const initializeRoomData = async () => {
      const initialData = await loadInitialData(room.name, initialLayout);
      console.log("132",initialData.layout)
      initialData.layout && setLayout(initialData.layout);
      initialData.scrollText && setScrollText(initialData.scrollText);
      initialData.volume_admin && handleVolumeChange(initialData?.admin, initialData.volume_admin)
      initialData.volume_VirtualDJ && handleVolumeChange("VirtualDJ", initialData.volume_VirtualDJ)
    };
    initializeRoomData();
  }, [room.name, initialLayout]);

  async function adjustVolumeForIngress(participant, volumeLevel) {
    if (!participant) {
        console.warn("⚠️ No participant found!");
        return;
    }
    // Find the audio track
    const audioTrackPublication = [...participant.trackPublications.values()].find(
      track => track.kind === "audio" && track.track
    );
    
    if (!audioTrackPublication) {
        console.warn("⚠️ No audio track found for participant:", participant.identity);
        return;
    }

    const audioTrack = audioTrackPublication.track;
    console.log("🎵 Found audio track:", audioTrack);
    
    // ✅ Use LiveKit’s built-in setVolume() function (0 to 10 scale)
    const scaledVolume = volumeLevel // Convert 0.0-1.0 to 0-10 scale
    audioTrack.setVolume(scaledVolume);
    console.log(`🔊 Adjusted volume to: ${scaledVolume}`);
  }

  const handleVolumeChange = (id,value) => {
    if (!room || !room.remoteParticipants) {
        console.warn("⚠️ LiveKit room or remoteParticipants is not initialized yet!");
        return;
    }
    
    console.log("🔍 Available remote participants:", room.remoteParticipants);

    const participant = Array.from(room.remoteParticipants.values()).find(
        p => p.identity === id
    );

    if (!participant) {
        console.warn(`⚠️ Participant virtualDJ not found.`);
        return;
    }

    adjustVolumeForIngress(participant, value);
};
  const handleReceived = (payload: Uint8Array) => {
    try {
      const strData = new TextDecoder().decode(payload);
      const data = JSON.parse(strData);
      console.log(195,data)
      if (data.layout) {
        console.log("144",data.layout)
        if(data.playAll) {
          setPlayAll(true)
        }else{
          setPlayAll(false)
        }
        console.log("data.layout: ", data.layout);
        
        setLayout(data.layout);
        console.log(room.name, data.layout);
        saveDataToFile(room.name, "layout", data.layout);
      } else if (data.scrollText) {
        console.log(data);
          if (data.action === 'append'){
            setScrollText(`${scrollText} ${data.scrollText}`);
            saveDataToFile(room.name, "scrollText", scrollText+" "+data.scrollText);
          } else if (data.action === 'edit'){
            setScrollText(data.scrollText);
            saveDataToFile(room.name, "scrollText", data.scrollText);
          } else if (data.action === 'remove'){
            setScrollText('');
            saveDataToFile(room.name, "scrollText", '');
          }
      } else if (data.participant || data.volume) {
        console.log(220,data.participant, data.volume);
        handleVolumeChange(data.participant.identity,data.volume)
      }      
    } catch (error) {
      console.error("Error processing received data:", error);
    }
  };
console.log(scrollText);


  useEffect(() => {
    if (room) {
      room.on(RoomEvent.DataReceived, handleReceived)
      EgressHelper.setRoom(room);

      EgressHelper.onLayoutChanged((newLayout) => {
        console.log("172",newLayout);
        
        // setLayout(newLayout);
      });

      let hasTrack = false;
      for (const p of Array.from(room.remoteParticipants.values())) {
        if (p.trackPublications.size > 0) {
          hasTrack = true;
          break;
        }
      }

      if (hasTrack) {
        EgressHelper.startRecording();
      } else {
        room.once(RoomEvent.TrackSubscribed, () => EgressHelper.startRecording());
      }
    }
    return () => {
      if (room) {
        room.off(RoomEvent.DataReceived, handleReceived);
      }
    };
  }, [room]);

  useEffect(() => {
    if (screenshareTracks.length > 0 && screenshareTracks[0].publication) {
      setHasScreenShare(true);
    } else {
      setHasScreenShare(false);
    }
  }, [screenshareTracks]);

  const allTracks = useTracks(
    [Track.Source.Camera, Track.Source.ScreenShare, Track.Source.Unknown],
    {
      onlySubscribed: true,
    },
  );
  
  let filteredTracks = allTracks.filter(
    (tr) =>
      tr.publication.kind === Track.Kind.Video &&
      tr.participant.identity !== room.localParticipant.identity,
  );

  let interfaceStyle = 'dark';
  if (layout?.endsWith('-light')) {
    interfaceStyle = 'light';
  }

  let containerClass = 'roomContainer';
  if (interfaceStyle) {
    containerClass += ` ${interfaceStyle}`;
  }

  
  console.log(layout);

  // determine layout to use
  let main: ReactElement = <></>;
  let effectiveLayout = layout;
  if (hasScreenShare && layout?.startsWith('grid')) {
    effectiveLayout = layout.replace('grid', 'speaker');
  }
  if (room.state !== ConnectionState.Disconnected) {
    if (effectiveLayout?.startsWith('speaker')) {
      main = <SpeakerLayout tracks={filteredTracks} />;
    } else if (effectiveLayout?.startsWith('single-speaker')) {
      main = <SingleSpeakerLayout tracks={filteredTracks} />;
    } else if (effectiveLayout?.startsWith('manual-focus')) {
      main = <ManualFocusSpeaker tracks={filteredTracks.filter(
      (tr) =>
        tr.publication.kind === Track.Kind.Video &&
        tr.participant.identity == effectiveLayout.split('-').slice(2).join('-'),
    )} />;
    } else if (effectiveLayout?.startsWith('grid')) {
      main = (
        <GridLayout tracks={filteredTracks}>
          <ParticipantTile />
        </GridLayout>
      );
    } else {
      main = (
        <CustomGridLayout tracks={filteredTracks} playAll={playAll}>
          <ParticipantTile />
        </CustomGridLayout>
      );
    } 
  }

  return (
    <div className={containerClass}>
    <style>
      {`
          @keyframes marquee {
              0% { transform: translateX(0%); }
              100% { transform: translateX(-100%); }
          }
      `}
    </style>
    {/* <div style={logoContainerStyle}>
      <img src={`/images/Default.png`} alt="Logo" style={logoStyle} />
    </div> */}
    {scrollText && (
      <div style={marqueeContainerStyle}>
        <div style={marqueeStyle}>
          <div style={textStyle}>
            {scrollText}
          </div>
        </div>
      </div>
    )}
    {main}
    {!muted && <RoomAudioRenderer />}
  </div>
  );
}